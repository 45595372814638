/* --------------------------------------------------

Component: AnimatedCountUp

Funktion:
  - Animiertes Hochzählen auf in Props definierten Zielwert. 

Was muss man beachten: 
  - Animation wird durch die Bool-Variable 'inView' getriggert.
  - FontSize wird als rem interpretiert

Beispiel:
  <AnimatedCountUp count={10} fontSize={2} duration={1} inView={inView} colorLight='brand.green' colorDark='brand.green' />


----------------------------------------------------- */

import React from 'react'
import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionBox = motion(Box);

export default function AnimatedCountUp({ 
  count, 
  fontSize, 
  duration, 
  inView, 
  numberColor, 
  cellHeight, 
  letterSpacing
}) {
  const numbersArray = Array(count).fill(0)
  const fraction = 100 / numbersArray.length
  return (
    <Box
      width='fit-content'
      display='flex'
      flexDirection='column'
      overflowY='hidden'
      position='relative'
      fontSize={fontSize}
      height={cellHeight}
      color={numberColor}
      flexShrink='0'
    >
      <MotionBox
        display='flex'
        flexDirection='column'
        color='"#14ffa4'
        flexShrink='0'
        initial={{
          y: fraction + '%',
          opacity: 0
        }}
        animate={
          inView ? {
            y: - (numbersArray.length - 1) * fraction + '%',
            opacity: [0, 1, 1],
            transition: {
              duration: duration, ease:[.75,-0.01,.11,1.01]
            }
          } : {}}
      >
        {Array(count).fill(0).map((number, index) => {
          return (
            <MotionBox
              as='span'
              key={index}
              fontSize={fontSize}
              lineHeight={cellHeight}
              textAlign='right'
              letterSpacing={letterSpacing}
            >
              {number + index + 1}
            </MotionBox>
          )
        })}
      </MotionBox>
    </Box>
  )
}
