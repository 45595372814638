import React from "react"
import { Container, Box, Text, Button } from "@chakra-ui/react"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgTextModule from "../ImgTextModule"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
function BannerBlepharo() {
  return (
    <Container variant="layoutContainer" minH="56vh">
      <ImgTextModule
        src={"483658.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="56vh"
        mt={4}
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        mediaOpacity={1}
        bgColor="brand.gray.100"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text color="brand.tan.vlight" fontSize="xl">
              Zum Beispiel:
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="brand.tan.ulight"
              fontWeight="normal"
              fontSize={{ base: "4xl", lg: "6xl" }}
            >
              Oberlidstraffung
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              color="brand.tan.vlight"
              mb={24}
              maxW={{ base: "100%", lg: "50%" }}
              fontSize="2xl"
            >
              Blepharoplastik
            </Text>

            <RouterLink isExternal={false} link={"/aesthetics/blepharoplastic"}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.violet.medviolet"
                color="brand.white"
              >
                Weiter
              </Button>
            </RouterLink>
          </FadeInAnimation>
        </Box>
      </ImgTextModule>
    </Container>
  )
}

export default BannerBlepharo
