import { Box, Button, Container, GridItem, Text } from "@chakra-ui/react"
import React from "react"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
import ImgTextModule from "../ImgTextModule"
function QuadTeaserFacelift() {
  return (
    <Container
      variant="layoutContainer"
      mt={4}
      mb={0.5}
      display="grid"
      px={{ base: 4, md: 12, lg: 6, xl: 0 }}
      gridGap={4}
      gridTemplateColumns={{
        lg: "repeat(6, 2fr);",
        md: "repeat(3, 1fr);",
        base: "repeat(1, 1fr);",
      }}
    >
      <GridItem colSpan={{ base: "2", md: "3", lg: "4" }}>
        <ImgTextModule
          w="100%"
          src={"235354.mp4"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          colStart={1}
          colEnd={3}
          mediaOpacity={0.8}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8} minH="450px">
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  High Lamellar SMAS Lift
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text my={4} color="brand.white" fontSize="lg">
                  Die Muskel-Bindegewebsschicht unter der Haut von Gesicht und
                  Hals (SMAS – Superfizielles musculoaponeurotisches System)
                  wird durch eine spezielle Operationstechnik neu ausgerichtet.
                </Text>
              </FadeInAnimation>
              <RouterLink isExternal={false} link={"/aesthetics/faceliftsmas"}>
                <Button
                  mt={4}
                  variant="solid"
                  bg="brand.tan.primary"
                  color="brand.darkgray"
                >
                  SMAS Facelift
                </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </GridItem>
      <GridItem colSpan={{ base: "2", md: "3", lg: "2" }}>
        <ImgTextModule
          w="100%"
          src={
            "343869_Waterdrops_Splashing_Body_Portrait_By_Ira_Belsky_Artlist_HD.mp4"
          }
          delay={0.9}
          duration={1}
          threshold={0.4}
          colStart={3}
          colEnd={4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={0.8}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8} minH="450px">
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Liquid Facelift
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text my={4} color="brand.white" fontSize="lg">
                  Die gewünschten Gesichtspartien werden per Injektion mit
                  Hyaluron aufgepolstert.
                </Text>
                <RouterLink
                  isExternal={false}
                  link={"/aesthetics/faceliftliquid"}
                >
                  <Button
                    mt={4}
                    variant="solid"
                    bg="brand.tan.primary"
                    color="brand.darkgray"
                  >
                    Liquid Facelift
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </GridItem>
      <GridItem colSpan={{ base: "2", md: "3", lg: "2" }}>
        <ImgTextModule
          w="100%"
          src={"andrey-zvyagintsev-T4bFs7q9E94-unsplash.jpg"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={false}
          colStart={1}
          colEnd={3}
          mediaOpacity={0.8}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8} minH="450px">
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Mannequinlift
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text my={4} color="brand.white" fontSize="lg">
                  Hautstraffung durch klassische ästhetische Chirurgie
                </Text>
                <RouterLink
                  isExternal={false}
                  link={"/aesthetics/faceliftmannequin"}
                >
                  <Button
                    mt={4}
                    variant="solid"
                    bg="brand.tan.primary"
                    color="brand.darkgray"
                  >
                    Mannequin Facelift
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </GridItem>
      <GridItem colSpan={{ base: "2", md: "3", lg: "4" }}>
        <ImgTextModule
          w="100%"
          src={
            "285358_Woman_Model_Light_Beam_Naked_By_Hans_Peter_Schepp_Artlist_HD.mp4"
          }
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          colStart={1}
          colEnd={3}
          mediaOpacity={0.8}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8} minH="450px">
              <FadeInAnimation
                threshold={0.4}
                delay={0.7}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="white" fontSize="4xl">
                  MacsLift
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text my={4} color="brand.white" fontSize="lg">
                  Bei ausgeprägter Faltenbildung, <br />
                  mit evtl. Eigenfettunterspritzung und/oder Deep-Peeling
                </Text>
                <RouterLink isExternal={false} link={"/aesthetics/faceliftmax"}>
                  <Button
                    mt={4}
                    variant="solid"
                    bg="brand.tan.primary"
                    color="brand.darkgray"
                  >
                    MacsLift
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </GridItem>
    </Container>
  )
}

export default QuadTeaserFacelift
